import axios from 'axios'

export class Pergunta {
  static async obtemTodos (serie, ano) {
    return axios.get('/creche/perguntas/'+serie+'/'+ ano);
  }
  static async perguntaCrecheUmItem (id) {
    return axios.get('creche/pergunta/'+id);
  }
  static async cadastrar (payload) {
    return axios.post('/creche/perguntas', payload)
  }
  static async alterar (payload) {
    return axios.put('/creche/perguntas/'+payload.id, payload)
  }
  static async remover (id) {
    return axios.delete('creche/perguntas/delete/'+id)
  }

}
