import axios from "axios";

import pagina from "@/class/pagina";

export class SegmentoEscolar {
  static async obtemTodos() {
    const anoAtual = sessionStorage.getItem("anoSelecionado");

    return axios.get("/segmento/ano/" + anoAtual);
  }
  static async obtemSegmentosEsp() {
    return axios.get("/segmentos/do-servidor");
  }
  static async obtemSegmentosEscola(id) {
    return axios.get("/escola/"+ id +"/segmentos");
  }
  static async obtemTodosPG(n) {
    const routePg = pagina.routePg;

    const anoAtual = sessionStorage.getItem("anoSelecionado");

    return axios.get("/segmento/" + anoAtual + "/pg?page=" + n+routePg);
  }
  static async obtemUm(id) {
    return axios.get("/segmento/" + id);
  }
  static async cadastrar(payload) {
    return axios.post("/segmento", payload);
  }
  static async alterar(payload) {
    return axios.put("/segmento/" + payload.id, payload);
  }
  static async remover(id) {
    return axios.delete("/segmento/" + id);
  }

  static async listarSegmentosPorSerie(id) {
    return axios.get("/segmento/" + id + "/series");
  }

  static async listarSegmentosPorSerieExistente(escola, segmento) {
    let ano = sessionStorage.getItem("anoSelecionado");
    return axios.get(`escola/${escola}/segmento/${segmento}/series/${ano}`,
    {
      //cache
      headers: {
        'cache-control': 'max-age=3600',
      }
    }
    );
  }

  static async tipoSegmento(tipo) {
    return axios.get("segmento/tipo/" + tipo);
  }
}
