import axios from 'axios'

import pagina from "@/class/pagina";

export class SeriesEscolar {
  static async obtemTodos () {
    return axios.get('/series')
  }
  static async obtemTodosPG (n) {
    const routePg = pagina.routePg;

    return axios.get('/series/pg?page='+n+routePg)
  }
  static async obtemUm (id) {
    return axios.get('/series/'+id)
  }
  static async cadastrar (payload) {
    return axios.post('/series', payload)
  }
  static async editar (payload) {
    return axios.put('/series/'+payload.id, payload)
  }
  
  static async buscaPorSegmento (segmento_id) {
    return axios.get('series/segmento/'+segmento_id)
  }

  static async pesqSeriesCompleto (payload,num) {
    return axios.get('/series/pesquisa/'+payload+'/'+num+'/completa')
  }

}
